:root {
  --primary1: #c9a145;
  --primary2: #dc9900;
  --primary3: #d1a132;

  --secondary1: #000000;
  --secondary2: #1e1e1e;
  --secondary3: #2a2a2a;
  --secondary4: #252a3a;
  --secondary5: #3a3a3a;
  --secondary6: #313131;
  --secondary7: #26324b;

  --tertiory1: #ffffff;
  --tertiory2: #efefef;
  --tertiory3: #f3f3f3;
  --tertiory4: #d4d4d4;
  --tertiory5: #b0b0b0;
  --tertiory6: #929fac;
  --tertiory7: #6a737b;
  --tertiory8: #757575;

  --primaryBdr: #624b15;
  --secondaryBdr: #353535;

  --siteRed: #ff0000;
  --siteGreen: #279500;
}
:root .light-theme {
  --secondary1: #e3e3e3;
  --secondary2: #e9e9e9;
  --secondary3: #eeeeee;
  --secondary4: #ffffff;
  --secondary5: #26324b;

  --tertiory1: #1b1f29;
  --tertiory2: #efefef;
  --tertiory5: #a8adb7;

  --secondaryBdr: #ffffff;

  --cardBodyBG: #f3f3f3;
  --innerPagesHeadBG: #162046;
  --tabsActivBG: #f3f3f3;
  --inputText: #9399a5;
  --footerStaticBG: #dfdfdf;
  --footerScialBG: #f2f2f2;
  --textLighter: #202020;
  --textLightest: #6b6b6b;
  --bottomMenuBorder: #31353f;
}
