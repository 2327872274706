@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "./variable";

:root {
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --blackClr: #000;
  --whiteClr: #fff;
  --primaryClr: #ebbd00;
  --headClr: #042246;
  // --bodyClr: #eef2f5;67
  --bodyClr: #000a2c;
}

body::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

.sports_page .tab-content .tab-pane::-webkit-scrollbar {
  background: orange;
  /* color of the tracking area */
}

.sports_page .tab-content .tab-pane::-webkit-scrollbar {
  background-color: #c2c2c2;
  border-radius: 20px;
  border: 3px solid #c2c2c2;
}

body::-webkit-scrollbar {
  background: orange;
  /* color of the tracking area */
}

body::-webkit-scrollbar {
  background-color: #0a0f19;
  border-radius: 20px;
  border: 3px solid #0a0f19;
}

html,
body {
  background: var(--bodyClr);
  color: var(--blackClr);
  font-size: 14px;
  // font-family: "Montserrat", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "Montserrat";

  .loading_img {
    background: var(--secondary2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a,
.btn {
  text-decoration: none;
}

button:focus,
.btn:focus {
  outline: 0;
}

input:focus,
input.form-control:focus {
  outline: 0 !important;
  box-shadow: none;
}

.btn_primary {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
  border: 1px solid var(--primaryClr);
  border-radius: 2rem;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: var(--primaryClr) !important;
    color: var(--blackClr) !important;
    border-color: var(--primaryClr) !important;
    opacity: 0.9;
  }
}

.secondaryBtn,
.btn_secondary {
  background: var(--primaryClr) !important;
  color: var(--whiteText2);
  font-size: 1rem;
  font-weight: 600;
  font-family: sans-serif;
  letter-spacing: 0.8px;
  height: 2.95em;
  line-height: 2.95em;
  margin-left: 8px;
  padding: 0 2em;
  border: 0;
  border-radius: 25px;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_outline {
  background: transparent;
  color: var(--primaryClr);
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 1px solid var(--primaryClr);
  border-radius: 2rem;
  opacity: 0.9;
  transition: all 0.2s;

  &:hover {
    background: var(--primaryClr) !important;
    border-color: var(--primaryClr) !important;
    opacity: 1;
  }
}

.signup_btn {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  padding: 5px 15px;
  border: 1px solid var(--primaryClr) !important;
  border-radius: 30px;
  transition: all 0.2s;
  @media only screen and (max-width: 767.98px) {
    padding: 5px 10px;
  }
  &:hover {
    background: var(--primaryClr) !important;
    color: var(--blackClr) !important;
  }
}

.login_btn {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  border: 1px solid var(--primaryClr);
  border-radius: 30px;
  animation: all 0.2s;
  position: relative;
  padding: 5px 15px;
  min-width: 85px;
  text-transform: uppercase;
  @media only screen and (max-width: 767.98px) {
    min-width: auto;
    padding: 5px 10px;
  }
  span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--whiteClr);
    color: var(--blackClr);
    position: absolute;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    background: var(--primaryClr) !important;
    color: var(--blackClr) !important;
    border-color: var(--primaryClr) !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 12%),
      inset 0 1px rgb(255 255 255 / 4%);
  }
}

.form-control,
.form-control:focus {
  background: var(--whiteClr) !important;
  color: #331a09 !important;
  border-radius: 0 !important;

  &.is-invalid {
    box-shadow: none;
  }

  &::placeholder {
    color: #331a09;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #000c35 inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
}

.container {
  @media (min-width: 1200px) {
    max-width: 1480px;
  }
}

body {
  .header_bfrlgn {
    .theme-btn {
      width: 25px;
      margin: 0;
      .btn {
        width: 25px;
        height: 25px;
        padding: 3px;
        img {
          width: 16px;
          &.dark_theme {
            display: none;
          }
        }
        &:after {
          content: none;
        }
      }
    }
  }

  .theme-btn {
    width: 80px;
    margin: 100px auto 20px;
    display: none;
    .btn {
      background: var(--tertiory1);
      width: 80px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 10px;
      border-radius: 25px;
      position: relative;
      &:hover {
        background: var(--tertiory1);
      }
      img {
        width: 20px;
      }
      &:after {
        position: absolute;
        content: "";
        top: 4px;
        left: 0px;
        transform: translateX(5px);
        width: 30px;
        height: 30px;
        background: var(--primaryClr);
        border-radius: 50%;
        transition: all 0.3s;
      }
    }
  }

  &.light-theme {
    .header_bfrlgn {
      img {
        &.dark_theme {
          display: block !important;
        }

        &.light_theme {
          display: none;
        }
      }
    }

    .theme-btn {
      .btn {
        &:after {
          transform: translateX(40px);
        }
      }
    }
  }
}

.header {
  margin-bottom: 20px;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: #001244;
  width: 100%;
  height: 100px;
  line-height: 100px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.137);

  .searchbar {
    background-color: #005ca2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--whiteClr);
      font-size: 20px;
    }
  }

  .menu-btn {
    background: transparent;
    border: 0 !important;
    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }
  }
  @media (max-width: 991px) {
    height: 65px;
    line-height: 65px;
  }
  .logo {
    width: 200px;
    @media (max-width: 575.98px) {
      width: 90px;
    }
    @media (max-width: 359.98px) {
      width: 75px;
    }
    a {
      display: block;
      img {
        width: 100%;
        height: auto;
        max-height: 100px;
      }
    }
  }

  .nav {
    .nav-link {
      color: var(--whiteClr);
      font-size: 18px;
      font-family: "Montserrat";
      font-weight: 600;
      line-height: 40px;
      display: block;
      padding: 3px 5px;
      margin: 0 8px;
      position: relative;

      &:hover,
      &:focus,
      &.active {
        color: var(--primaryClr);
      }

      &::after {
        position: absolute;
        content: "";
        bottom: 50%;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background: transparent;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        z-index: -1;
      }

      &:hover::after,
      &:focus::after,
      &.active::after {
        bottom: -2px;
        background: var(--primaryClr) !important;
        opacity: 1;
        visibility: visible;
        z-index: 9;
      }
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--textClr);

    .path-stroke {
      stroke: var(--textClr);
    }

    ul {
      display: flex;
      align-items: center;

      li {
        color: var(--tertiory1);
        margin-right: 15px;
        line-height: 25px;
        position: relative;

        @media only screen and (max-width: 575.98px) {
          margin-right: 6px;
        }

        img {
          width: 30px;
          max-width: 30px;
        }

        .deposit-header {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
        }

        .notification-count {
          background: var(--whiteClr);
          color: var(--headClr);
          font-weight: 600;
          border-radius: 25px;
          padding: 0 5px;
          width: 116px;
          height: 35px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .balance-amount {
          background: var(--whiteClr);
          color: var(--headClr);
          font-weight: 600;
          border-radius: 25px;
          padding: 0 5px;
          width: 140px;
          height: 35px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;

          img {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
    }

    .menu-btn {
      padding: 0;
      background: none;
      border: none;

      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }

      svg {
        width: 21px;
        height: auto;
        fill: var(--textClr);
      }
    }
  }
}

.logo,
.footer-logo {
  a {
    width: 200px !important;
    @media only screen and (max-width: 991.98px) {
      width: 90px !important;
    }
  }

  img {
    &.light_theme {
      visibility: hidden;
      width: 0;
    }
  }
}

.light-theme {
  .logo,
  .footer-logo {
    img {
      &.light_theme {
        visibility: visible;
        width: auto;
      }

      &.dark_theme {
        visibility: hidden;
        width: 0;
      }
    }
  }
}

.light-theme {
  .header {
    .headerRight {
      ul {
        li {
          &:not(.balance) {
            img {
              filter: none;
            }
          }
        }
      }
    }
  }
}

.back-link {
  background: var(--headClr);
  color: var(--primaryClr);
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: max-content;
  line-height: 20px;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  transition: all 0.3s;

  svg {
    margin-left: 0.5rem;
    transition: all 0.2s;
  }

  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--blackClr);
    flex-direction: row-reverse;

    svg {
      margin-left: 0;
      margin-right: 0.5rem;
    }
  }
}

.offcanvas {
  border: none !important;
  z-index: 999999999 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
}

.offcanvas-header {
  background: var(--innerPagesHeadBG);
  color: var(--innersec--headClr);

  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--headClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--myProfileHeadText);

    svg {
      fill: gold;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .user_name,
    p {
      color: var(--secondary4);
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
  }

  .btn-close {
    opacity: 1;
    filter: brightness(0.5);

    &:focus {
      box-shadow: none;
    }
  }
}

.light-theme {
  .offcanvas {
    .btn-close-white {
      filter: invert(1) !important;
    }
  }
}

.offcanvas-body {
  background: var(--bodyClr);
  padding: 1rem 3rem;

  .balance {
    color: var(--whiteClr);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;

    img {
      margin-right: 14px;
      width: 24px;
    }
  }

  .balance-amount {
    color: var(--whiteClr);
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }

  .menu-items {
    margin-top: 2rem;
    padding-left: 2rem;

    li {
      a {
        display: flex;
        align-items: center;
        padding: 12px 1rem;
        font-size: 16px;
        color: var(--headClr);
        font-size: 16px;
        font-weight: 600;

        svg,
        img {
          margin-right: 15px;
          width: 22px;
          height: auto;
          fill: var(--primaryClr);
          stroke: var(--primaryClr);
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .deposit_btn {
    background: #ff422e;
    color: var(--whiteText);
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: 1px solid var(--primaryClr);
    border-top-color: rgb(252, 194, 0);
    border-right-color: rgb(252, 194, 0);
    border-bottom-color: rgb(252, 194, 0);
    border-left-color: rgb(252, 194, 0);
    border-radius: 2rem;
    opacity: 0.9;
    transition: all 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    &:hover {
      background: #ff422e;
      opacity: 0.8;
    }
  }
}

.form-steps {
  text-align: center;
}

.main {
  min-height: calc(100vh - 447px);
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 65px;
    padding-bottom: 10px;
  }

  .TopCategories {
    margin-bottom: 20px;
    border-top: 1px solid #d2d7ea;
    border-bottom: 1px solid #d2d7ea;

    ul {
      display: flex;
      margin: 0;
      list-style: none;
      overflow-x: auto;

      li {
        text-align: center;
        min-width: 80px;

        a {
          color: var(--primaryClr);
          font-size: 1rem;
          font-weight: 400;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          .IconHolder {
            border-radius: 5px;
            padding: 5px 8px 0 8px;
            width: 60px;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span {
            padding: 3px 0 2px;
            white-space: nowrap;
          }
        }

        &.active {
          background: var(--primaryClr);

          a {
            color: var(--whiteText);
          }
        }
      }
    }
  }

  .mainBanner {
    .slick-slide {
      .content {
        position: absolute;
        left: 0;
        width: 60%;
        padding: 10px 0;
        margin: auto;

        h2 {
          color: var(--whiteText);
          font-size: 3rem;
          max-width: 380px;

          span {
            color: var(--primaryClr);
          }
        }

        p {
          color: var(--whiteText);
          font-size: 1.1rem;
          max-width: 350px;
        }
      }

      img {
        width: 100%;
        border-radius: 1rem;

        @media (min-width: 768px) {
          max-height: 620px;
          object-fit: cover;
        }
      }
    }

    .slick-dots {
      bottom: -15px;

      li {
        width: 10px;
        height: 10px;
        margin: 0 0.25rem;

        button {
          width: 10px;
          height: 10px;
          background: var(--tertiory1);
          padding: 0;
          opacity: 0.5;
          border-radius: 50%;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  .Live_casino_tabs {
    .nav-tabs {
      background: #1c2634;
      flex-wrap: nowrap;
      overflow-x: auto;
      border-bottom: 0;

      .nav-item {
        .nav-link {
          background-image: ("./public/images/game-image/Roulette_Icons.png");
          color: var(--whiteText);
          font-size: 14px;
          background-repeat: no-repeat;
          background-repeat: no-repeat;
          background-position: 36px 10px;
          background-size: 50px 50px;
          padding: 65px 5px 5px 5px;
          width: 125px;
          height: 100%;
          border: 0;
          border-radius: 0px !important;

          &:hover,
          &.active {
            color: var(--primaryClr);
            border: 0;
            border: 1px solid #daa52000;
          }

          &.active {
            background-image: ("./public/images/Roulette_Icons.png");
          }
        }
      }
    }

    .top_Sec {
      display: grid;
      grid-gap: 1.5rem 0.75rem;
      grid-template-columns: repeat(4, 1fr);
      padding: 1rem 0.75rem;
    }
  }

  .sectionTitle {
    font-size: 20px;
    font-weight: 600;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    text-transform: uppercase;

    &:after {
      content: " ";
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 70%;
      height: 2px;
      background: var(--primaryClr);
    }
  }

  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 10px #0f121a;

    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteText);
      text-transform: uppercase;
      padding: 8px;
    }
  }

  .payment-info {
    background: var(--headClr);
    color: var(--tertiory1);
    padding: 15px 10px;
    border-radius: 2px;
    .balance {
      color: var(--whiteClr);
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: #fff;
        stroke: #fff;
      }
    }

    .balance-amount {
      color: var(--whiteClr);
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }

    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      border-radius: 25px;
      border-color: var(--whiteClr);
      color: var(--whiteClr);

      &.btn-outline-secondary {
        border-color: var(--whiteText);
        color: var(--whiteText);
      }

      &.btn_withdraw {
        color: var(--whiteClr);
        width: 100%;
        border-color: var(--whiteClr);
      }

      &.btn_primary {
        background: var(--primaryClr);
        border-color: var(--primaryClr);
        color: var(--blackClr);
        width: 100%;
      }
    }
  }

  .account-statement {
    input {
      background-color: transparent !important;
      color: var(--whiteClr) !important;
      z-index: 9 !important;
      &::placeholder {
        color: #929fac !important;
      }
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    button.calender-icon {
      svg {
        opacity: 0;

        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      background-color: #929fac;
      border-color: #929fac;
      height: 32px;
      border-radius: 0;
    }
    h3 {
      font-size: 16px;
      margin-top: 20px;
      padding-bottom: 5px;
      color: var(--whiteClr);
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      border-top: 1px solid #ddd;
      li {
        padding: 22px 1rem;
        border-bottom: 2px solid #fff;
        .h4 {
          color: var(--whiteClr);
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
        }
        .h5 {
          color: var(--whiteClr);
          font-size: 12px;
          letter-spacing: 0.86px;
          margin-bottom: 0;
        }
        .details {
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 300;
          span:last-child {
            color: red;
            cursor: pointer;
          }
        }
        .statue {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.7px;
          background: #dcdcdc;
          padding: 3px;
          border-radius: 3px;
        }
        small {
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 300;
          display: block;
          margin-top: 10px;
          opacity: 0.6;
        }
        span {
          color: #3d4244;
        }
      }
    }

    table {
      thead {
        background: #a3a3a3;

        tr {
          th {
            color: #fff;
            white-space: nowrap;
          }
        }
      }

      tbody {
        background: #7b7b7b;
        color: #fff;

        tr {
          td {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      max-width: 160px;
      width: 160px;
      height: 160px;
      border-radius: 10%;
      border: 2px solid var(--primaryClr);
    }
    span {
      color: var(--whiteClr);
      font-size: 14px;
      font-weight: 500;
      margin-top: 8px;
    }
  }

  .edit-btn {
    background: var(--primaryClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteText);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .profile-info {
    background: var(--headClr);

    th {
      background: var(--headClr);
      color: var(--whiteClr);
    }

    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;

    .form-control {
      background: var(--lytThemeClr1);
      color: var(--whiteText);
      border-color: var(--primaryClr);
    }

    .dropdown-toggle {
      background: var(--headClr) !important;
      border-radius: 0;
      padding: 5px 15px;
      border: 1px solid var(--primaryClr) !important;
      color: var(--tertiory1);
      width: 100%;
      text-align: left;
      position: relative;
    }

    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      position: absolute;
      right: 10px;
      top: 50%;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: var(--tertiory1);
      text-decoration: none;
      background: var(--headClr) !important;
      border-radius: 0;
    }

    .btn-outline-primary {
      padding: 5px 10px;
      margin-left: 2px;
      background: #252a3a;
      border-color: var(--primaryClr);
      border-radius: 0 !important;
    }

    .dropdown-menu {
      background: var(--headClr);
      border-radius: 0;
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -2px;

      a {
        color: var(--tertiory1);
        font-size: 14px;
        padding: 0.75rem 1rem;
        margin: 0;
        border: 0;
        border-bottom: 1px solid var(--secondaryBdr);

        &:last-child {
          border: none;
        }

        &:hover {
          background: transparent !important;
          color: rgba(38, 50, 75, 0.8) !important;
          border: 0 !important;
          border-bottom: 1px solid rgba(232, 232, 232, 1) !important;
        }
      }
    }
  }

  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;

    svg {
      fill: var(--whiteText);
    }
  }
}

.steps-canvas {
  &.LoginImg {
    width: 100%;
    @media only screen and (max-width: 767.98px) {
      max-width: 400px;
    }
    &::after {
      content: "";
      background: url(./assets/images/banner/loginimg.jpg);
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
      width: 70%;
      height: 100%;
      position: absolute;
      @media only screen and (max-width: 1199.98px) {
        max-width: calc(100% - 400px);
      }
    }
  }
  .offcanvas-header {
    background: var(--whiteClr);
    width: 30%;
    margin-left: auto;
    @media only screen and (max-width: 1199.98px) {
      min-width: 400px;
    }
    @media only screen and (max-width: 767.98px) {
      min-width: 100%;
    }
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--headClr);
    }
    .btn-close {
      filter: brightness(0.5) !important;
    }
  }
  .offcanvas-body {
    width: 30%;
    margin-left: auto;
    @media only screen and (max-width: 1199.98px) {
      min-width: 400px;
    }
    @media only screen and (max-width: 767.98px) {
      min-width: 100%;
    }
  }
  &.signup-pages {
    .offcanvas-header,
    .offcanvas-body {
      width: 100%;
      margin-left: 0;
    }
  }
  .form-steps {
    padding: 20px 0;
    .sportsSection {
      margin: 35px auto 0;
      img {
        width: 100px;
      }
    }
    ul li {
      width: 15%;
    }
    h1 {
      font-size: 22px;
      font-weight: bold;
      padding-top: 20px;
      padding-bottom: 5px;
    }
    .h2 {
      color: var(--whiteClr);
      font-size: 28px;
      font-weight: 500;
    }
    h4 {
      color: var(--whiteClr);
    }
    p {
      color: var(--whiteClr);
      font-size: 18px;
      font-weight: 300;
    }
    input {
      background: #000c35 !important;
      color: var(--whiteClr) !important;
      padding: 14px 20px;
      border: 1px solid hsla(0, 0%, 100%, 0.2) !important;
      border-radius: 10px;
      &::placeholder {
        color: var(--whiteClr) !important;
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      width: 20px;
      img {
        filter: invert(1);
      }
    }
    .react-tel-input {
      .flag-dropdown {
        border-radius: 8px 0 0 8px;
        .selected-flag {
          border-radius: 8px 0 0 8px;
        }
      }
    }
    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--whiteClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      color: var(--whiteClr);
      a {
        font-size: 16px;
        color: var(--whiteClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.offcanvas {
  &.user_section {
    max-width: 90%;

    .offcanvas-header {
      background: var(--tertiory1);
    }

    .offcanvas-body {
      background: var(--whiteClr);
      padding: 0;

      .upperbalnace {
        background-color: var(--headClr);
        padding: 15px;
      }

      .btn_primary {
        background: var(--primaryClr);
      }

      .menu-items {
        padding-left: 0;
        margin-top: 0;
        border-top: 1px solid var(--secondaryBdr);

        li {
          border-bottom: 1px solid var(--secondaryBdr);

          a {
            padding: 1rem;
          }
        }
      }

      .btn_withdraw {
        background: transparent;
        color: var(--whiteClr);
        padding: 0.5rem 1rem;
        border-color: var(--whiteClr) !important;
        border-radius: 25px;
      }

      .btn_deposit {
        background: var(--primaryClr);
        color: var(--whiteText);
        width: 100%;
        border-color: var(--primaryClr) !important;
        border-radius: 25px;
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;

  ul {
    flex-wrap: nowrap;
    border: 0;

    &.nav-tabs {
      @media screen and (max-width: 575.98px) {
        width: 100vw;
        margin-right: calc(var(--bs-gutter-x) * -0.5);
        margin-left: calc(var(--bs-gutter-x) * -0.5);
      }
    }

    li {
      width: 100%;

      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: var(--whiteClr);
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
        padding: 15px 10px;
        border: 0;
        border-radius: 0;

        &.active {
          background: var(--primaryClr);
          color: var(--blackClr);
        }
      }
    }
  }

  .tab-content {
    .bank-details {
      overflow: hidden;
      max-width: 400px;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: 0 2px 12px rgb(0 0 0 / 18%);

      .top-area {
        background: var(--secondary5);
        color: var(--tertiory1);
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        padding: 20px;
      }

      .bottom-area {
        background: var(--secondary6);
        padding: 20px;

        .details {
          margin-bottom: 0;

          h5 {
            color: var(--tertiory1);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }

          li {
            display: flex;
            align-items: center;

            div {
              color: var(--tertiory1);
              font-size: 14px;
              font-weight: 300;
              padding: 5px 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &:first-child {
                width: 130px;
              }
            }
          }
        }
      }
    }

    .upi-list {
      margin: 20px 0 40px;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }

    form {
      padding: 30px;
      text-align: center;
      .info_text {
        color: var(--whiteClr);
        text-align: left;
        padding-left: 1rem;
        margin-bottom: 0.5rem;
      }
      .text_actype {
        color: var(--whiteClr);
        font-size: 15px;
        font-weight: 500;
        text-align: left;
      }
      input {
        padding: 14px 20px;
        &.form-check-input {
          background: var(--tertiory1);
          width: 24px;
          height: 24px;
          padding: 0;
          border: 2px solid var(--primaryClr);
          border-radius: 50px;
          position: relative;
          &:checked:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            background: var(--primaryClr);
            width: 16px;
            height: 16px;
            display: block;
            border-radius: 50px;
          }
        }
      }
      .form-check-inline {
        margin-right: 3rem;
        .form-check-input {
          cursor: pointer;
        }
        .form-check-label {
          color: var(--whiteClr);
          font-size: 15px;
          font-weight: 300;
          padding-left: 10px;
          cursor: pointer;
        }
      }
      .btn {
        background: var(--primaryClr);
        padding: 9px 30px !important;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
        border: 0;
        border-radius: 25px;
      }

      .bank_saved_info {
        background: var(--secondary5);
        padding: 20px 20px 30px;
        border-radius: 10px;
        position: relative;

        .bank_name {
          position: relative;

          .form-check {
            .form-check-label {
              font-size: 14px;
              font-weight: 700;
            }
          }

          .acc_num {
            position: absolute;
            top: 25px;
            left: 35px;
          }
        }

        .dropdown {
          position: revert;

          .dropdown-toggle {
            background: transparent;
            min-width: auto;
            padding: 0;
            margin-top: 10px;
            border: 0;

            &:after {
              content: url("/assets/images/menu-dots.png");
              filter: brightness(4);
              border: 0;
            }
          }

          .dropdown-menu {
            transform: translate(0, 85px) !important;
            background: var(--secondary6);
            width: 100%;
            padding: 10px 20px;
            border: 0px;
            border-radius: 0px 0px 10px 10px;

            .dropdown-item {
              padding: 0 0.5rem;

              @media screen and (max-width: 575.98px) {
                padding: 0;
              }

              &:hover,
              &:focus {
                background: transparent;
                cursor: text;
              }

              .bank_details {
                li {
                  color: var(--tertiory2);
                  display: flex;
                  align-items: center;
                  margin-bottom: 0.5rem;

                  b {
                    min-width: 120px;
                    font-size: 0.85rem;

                    @media screen and (max-width: 575.98px) {
                      min-width: 110px;
                    }
                  }

                  span {
                    @media screen and (max-width: 575.98px) {
                      font-size: 0.85rem;
                      font-weight: 500;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .card-holder {
      height: 115px;
      background: var(--tertiory1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      img {
        max-height: 40px;
      }
    }
  }

  .h4 {
    font-size: 14px;
    font-weight: 500;
  }

  .sectionTitle {
    &::after {
      background: var(--lytThemebackClr3);
      width: 100%;
    }
  }

  .sports_sec {
    img {
      border-radius: 10px;
    }

    .dropdown {
      .dropdown-toggle {
        background: var(--headClr);
        color: var(--tertiory1);
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem;
        border-color: var(--primaryClr);
        border-radius: 0;
        position: relative;

        &::after {
          position: absolute;
          right: 20px;
          top: 50%;
        }
      }

      .dropdown-menu {
        background: var(--headClr) !important;
        width: 100% !important;
        padding: 0;
        margin-top: -2px;
        border: 1px solid var(--secondaryBdr);
        border-top: 0;
        border-radius: 0;

        .dropdown-item {
          color: var(--tertiory1);
          padding: 10px 10px;
          opacity: 0.7;

          &:not(:last-child) {
            border-bottom: 1px solid var(--secondaryBdr);
          }

          &:hover {
            background: var(--headClr) !important;
            opacity: 1;
          }
        }
      }
    }
  }
}

.custom-tabs {
  .nav {
    flex-wrap: nowrap;
    background: #f2f2f2;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    li {
      margin: 0 10px;

      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 10px 0;
        color: var(--textClr);
        font-weight: 500;
        border: none;

        &.active {
          color: var(--primaryClr);
          background: none;
        }
      }
    }
  }

  .tab-pane {
    .title {
      background: #d8d8d8;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: var(--primaryClr);
        color: var(--whiteText);
      }
    }

    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          color: #838383;
        }
      }

      .status {
        background: #4caf50;
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }

      .listing {
        display: flex;
        flex-wrap: nowrap;

        li {
          margin: 0 2px;
          width: 100%;

          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #899d8a;
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      background: #d8d8d8;
      margin: 20px 0;
      padding: 0 1rem;

      .bet-btns {
        display: flex;

        li {
          background: #4caf50;
          color: var(--whiteText);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }

    .status-btns {
      display: flex;
      align-items: center;

      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteText);

        &.no {
          background: #796b4a;
        }

        &.yes {
          background: #2b3970;
        }
      }
    }

    .game-points {
      ul {
        margin: 0 -1rem;
        margin: 0;

        li {
          display: flex;
          border-bottom: 1px solid var(--lytThemeClr2);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;

          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;

            .time {
              color: #af2f2f;
            }
          }

          .score {
            margin-left: auto;
          }

          .status-btns {
            margin-left: 5px;
            position: relative;

            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;

              span {
                font-size: 12px;
              }
            }

            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteText);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .game-top-area {
      background: #d8d8d8;
      padding: 10px 1rem;

      .date {
        text-align: center;
        color: #4caf50;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }

      .range {
        background: #4caf50;
        font-size: 11px;
        color: var(--whiteText);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.footer {
  padding: 50px 0 0px;
  background: #001244 !important;
  .sectionDivide {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .singleSection {
      width: 45%;
    }
  }

  p {
    color: #899fdb;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 36px;
  }

  .footer-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .providers_logo {
    padding-bottom: 30px !important;

    h4 {
      font-size: 20px;
      margin-bottom: 15px;
      color: var(--whiteClr);
    }

    @media (max-width: 1199.98px) {
      padding-bottom: 20px;
    }

    ul {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;

      li {
        margin-right: 20px;
        max-width: 18%;

        img {
          border-radius: 8px;
        }

        @media (max-width: 1199.98px) {
          max-width: 31%;
          margin: 1%;
        }
      }
    }
  }

  .footer-links {
    background: #000d31;
    margin-top: 28px;

    ul {
      text-align: center;
      padding: 21px 0;

      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;
        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 5px;
          width: 1px;
          height: 14px;
          background: #7aa3c2;
        }
        &:last-child {
          margin-right: 0;
          &:after {
            width: 0;
          }
        }
        a {
          color: #009fff;
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
        }
      }
    }
  }

  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        padding: 8px;

        img {
          max-height: 46px;
        }
      }
    }
  }

  .provider-img {
    width: 120px;
  }

  .foot-discription {
    padding-top: 25px !important;
  }

  .social-icons {
    ul {
      display: flex;
      justify-content: center;
      li {
        margin: 0px 10px;
        svg,
        img {
          width: 36px;
        }
      }
    }
  }

  .footer-menu {
    background: var(--headClr);
    border-top: 1px solid var(--primaryBdr);
    z-index: 99999;

    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        min-width: 54px;

        a {
          padding: 6px 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          height: 100%;

          img {
            max-height: 23px;
            filter: invert(1) brightness(20.5);

            &.active {
              display: none;
            }
          }

          span {
            color: var(--whiteClr);
            font-size: 11px;
            font-weight: 500;
            line-height: 15px;
            padding-top: 5px;
          }

          &.active {
            img {
              &.no_active {
                display: none;
              }

              &.active {
                display: block;
                filter: none !important;
              }
            }
          }
        }

        &.home {
          position: relative;

          a {
            position: absolute;
            bottom: -2px;
            left: -10px;
            width: 75px;
            height: 75px;

            img {
              width: 100%;
              height: 100%;
              max-height: 100%;
              filter: none !important;
            }
          }
        }
      }
    }
  }

  .paymentspartner {
    h5 {
      font-size: 20px;
      margin-bottom: 15px;
      color: var(--whiteClr);
      text-align: left;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 8px;

      li {
        img {
          width: 35px;
        }
      }
    }
  }
}

.light-theme {
  .footer-menu {
    border-color: #30343e;

    a {
      img {
        filter: brightness(1000);
      }
    }
  }
}

.middleContentSec .live_casino_page.live {
  margin-top: 0;
}

.live_casino_page {
  &.live {
    background: #1d1d27;
    color: #d8d8d8;
    margin-top: 100px;
    @media only screen and (max-width: 991.98px) {
      margin-top: 65px;
    }
    .ezugi_iframe {
      iframe {
        @media only screen and (max-width: 991.98px) {
          height: calc(100vh - 70px) !important;
        }
      }
    }
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);

      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // .Toastify__toast-container {
  //   z-index: 9999999 !important;
  //   position: relative;
  // }

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}

.games-all {
  .casino_games {
    .shadow.rounded.position-relative {
      padding: 0px;
      margin: 10px 0px;
      width: 100%;
    }
  }
}

#Games-select {
  width: 100%;
  font-size: 20px;
  padding: 15px 10px;
  background: #0e1422;
  color: var(--whiteText);
  border-radius: 10px;
  border: 1px solid gold;
  margin-bottom: 20px;

  option {
    border-radius: 10px;
    font-size: 12px;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 0px;
  }
}

.contact_sec {
  .heading {
    color: var(--whiteClr);
  }

  .contact_info {
    background: var(--whiteText4);
    border-radius: 10px 10px 0 10px;
    padding: 15px 40px;

    @media screen and (max-width: 576px) {
      padding: 15px 15px;
    }

    ul {
      li {
        display: flex;
        margin-bottom: 20px;
        a {
          display: flex;
          color: #fff;
        }
        .img_box {
          background: var(--primaryClr);
          width: 60px;
          height: 60px;
          line-height: 60px;
          text-align: center;
          margin-right: 20px;
          border-radius: 30px;

          img {
            width: 30px;
            height: auto;
          }
        }
        h5 {
          color: var(--whiteClr);
        }

        p {
          color: var(--whiteClr);
          font-size: 15px;
          max-width: 200px;
          margin-bottom: 0;
        }
      }
    }
  }

  form {
    .form-floating {
      background: var(--lytThemebackClr);
      margin-bottom: 1.5rem;
      border-radius: 10px;

      .form-control {
        color: var(--whiteText);
      }

      label {
        color: #6c757d;
      }
    }
  }

  .contact_img {
    img {
      animation: moving 3s infinite linear;

      @keyframes moving {
        0% {
          left: 0;
        }

        50% {
          left: 0;
          -webkit-transform: translateX(20px);
          transform: translateX(20px);
        }

        100% {
          left: 0;
        }
      }
    }
  }
}

.contact-area {
  .panel {
    background: var(--whiteText4);
    padding: 10px 20px;
    border-radius: 5px;

    svg {
      font-size: 30px;
    }
  }
}

.non_cash_balance {
  padding: 10px 10px;
  background: #0e1627;
  margin: 10px 0 20px;
  border-radius: 5px;

  .non_cash_balance_heading {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .non_cash_balance small {
      font-size: 12px;
      margin: 0px 10px;
      font-weight: 400;
      color: #959595;
    }
  }
}

.modal {
  &.changePasswordModal.show {
    .modal-dialog {
      // transform: translate(0, 250px);
      border: 0;
      .modal-content {
        .modal-header {
          background: var(--whiteClr);
          border: 0;
        }
        .modal-body {
          background: var(--bodyClr);
          .form-control {
            background: #000c35 !important;
            color: var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
              opacity: 0.5;
            }
          }
          .icon {
            position: absolute;
            top: 8px;
            right: 0.75rem;
            cursor: pointer;
            img {
              width: 20px;
              filter: brightness(10);
            }
          }
        }
        .modal-footer {
          background: var(--bodyClr);
          border: 0;
        }
      }
    }
  }
}

.changes_password {
  width: 100%;
  padding: 10px 10px;
  margin: 5px 0px;
  border: 1px solid goldenrod;
  border-radius: 5px;
  color: var(--whiteText);
  background: transparent;
}

.fade.changePasswordModal.modal.show .modal-dialog .modal-content {
  background: #18243d;
}

.fade.changePasswordModal.modal.show .btn.btn-primary {
  background: goldenrod;
  border: 0px;
}

.Support_page {
  .footer {
    .footer-links.mb-3 {
      display: none;
    }
  }
}

// Media Quary Start ======>>
@media only screen and (max-width: 1199.98px) {
  .main .mainBanner .slick-slide .content h2 {
    font-size: 2rem;
    max-width: 250px;
  }

  .main .Live_casino_tabs .top_Sec {
    grid-template-columns: repeat(3, 1fr);
  }

  .main .mainBanner .slick-slide .content p {
    font-size: 1rem;
    max-width: 300px;
  }
}

@media only screen and (max-width: 767.98px) {
  .searchGames {
    padding: 4px 4px !important;
    border-radius: 50%;
    font-size: large;

    p {
      display: none;
    }
  }

  .cards {
    width: 100% !important;
  }

  .primary_card .stats-card {
    display: grid !important;

    ul {
      width: 100% !important;
      border-right: 0 !important;
    }
  }

  .account-statement button {
    .calendar_icon {
      svg {
        display: block;
      }
    }
  }

  .Spribe_Games .Provider_Games a {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  .withdraw_btn a.btn_primary.btn-primary {
    margin-left: 0;
    margin-top: 12px;
  }

  .listings {
    .left-arrows {
      display: none;
    }
  }

  .Live_casino_tabs {
    .live_heading {
      img {
        width: 20% !important;
      }
    }

    .live_heading.ev-heading {
      img {
        width: 30% !important;
      }
    }
  }

  .main .mainBanner .slick-slide .content {
    width: 80%;
  }

  .main .mainBanner .slick-slide .content h2 {
    font-size: 1.5rem;
  }

  .main .mainBanner .slick-slide .content p {
    font-size: 0.85rem;
  }

  .footer {
    padding: 25px 0 0px;
  }

  // .Live_games_row {
  //   justify-content: center;
  //   align-items: center;

  //   .Live_games_col {
  //     width: auto;
  //   }
  // }
}

@media only screen and (max-width: 575.98px) {
  .main .Live_casino_tabs .nav-tabs {
    background: #0e1422;
    padding-bottom: 0px;
  }

  .main .Live_casino_tabs .top_Sec {
    grid-template-columns: repeat(3, 1fr);
  }

  #basic-navbar-nav .me-auto.nav .nav-link {
    display: none;
  }

  .live_heading {
    padding: 10px 0px;
    margin: 5px 0px;
    font-size: 26px;
  }

  .main .Live_casino_tabs .nav-tabs .nav-item .nav-link {
    background-position: 32px 10px;
    background-size: 30px 30px;
    padding: 45px 5px 5px 5px;
    width: 95px;
  }

  .live_heading img {
    width: 110px;
  }

  .Spribe_Games .shadow.rounded.position-relative {
    padding: 0px 10px;
  }

  .shadow.rounded.position-relative {
    width: 160px !important;
    padding: 0px 0px;
  }

  vailable .Payments_Games .shadow.rounded.position-relative {
    width: 85px !important;
  }

  .Provider_Games .shadow.rounded.position-relative {
    width: 120px !important;
    padding: 0 !important;
  }

  .Provider_Games .shadow.rounded.position-relative img {
    width: 100%;
    height: auto;
  }

  .listings.pt-5 {
    padding-top: 15px !important;
  }

  .footer .footer-links ul li {
    margin: 5px;

    padding: 0 3px;
  }

  .games-all .shadow.rounded.position-relative {
    min-width: calc(100% - 0px);
    width: calc(100% - 0px) !important;
  }

  // .Toastify__toast-container {
  //   z-index: 9999999 !important;
  //   position: relative;
  // }

  .listings .filter .btn-outline-primary {
    width: 100%;
  }

  .tabs-area {
    .tab-content {
      form {
        padding: 20px 40px 45px;
        text-align: center;
      }
    }
  }

  .main {
    .sectionTitle {
      margin-top: 20px !important;
      margin-bottom: 0;
      padding: 0;
    }
  }

  .main {
    .profile-img {
      padding-top: 30px;
    }
  }

  .choose_file h4 {
    left: 35%;
  }

  .mstrpassword {
    max-width: 28ch;
  }

  #Games-select {
    option {
      border-radius: 10px;
      font-size: 12px;
    }
  }

  .main .profile-img img {
    max-width: 100px;
    width: 100px;
    height: 100px;
  }

  .main .profile-img {
    padding-top: 0px;
  }

  #profile_page {
    padding-top: 80px;
  }

  .account_details {
    text-align: center;
  }

  .Payments_Games .shadow.rounded.position-relative img {
    width: 200px;
    height: auto;
  }
}

.main {
  .Live_casino_tabs {
    .nav-tabs {
      .nav-item {
        .nav-link.active {
          background-image: url("../public/images/Roulette_Icons.png");
          border: 0 !important;
        }

        .nav-link {
          background-image: url("../public/images/games-image/Roulette_Icons.png");

          &:hover {
            border: 0 !important;
          }
        }

        #uncontrolled-tab-example-tab-Baccarat.active {
          background-image: url("../public/images/Baccarat_Icons.png");
        }

        #uncontrolled-tab-example-tab-Baccarat {
          background-image: url("../public/images/games-image/Baccarat_Icons.png");
        }

        #uncontrolled-tab-example-tab-Andar\ Bahar.active {
          background-image: url("../public/images/Andar_bahar_Icons.png");
        }

        #uncontrolled-tab-example-tab-Andar\ Bahar {
          background-image: url("../public/images/games-image/Andar_bahar_Icons.png");
        }

        #uncontrolled-tab-example-tab-Poker.active {
          background-image: url("../public/images/Poker_Icons.png");
        }

        #uncontrolled-tab-example-tab-Poker {
          background-image: url("../public/images/games-image/Poker_Icons.png");
        }

        #uncontrolled-tab-example-tab-Blackjack.active {
          background-image: url("../public/images/Blackjack_icons.png");
        }

        #uncontrolled-tab-example-tab-Blackjack {
          background-image: url("../public/images/games-image/Blackjack_icons.png");
        }

        #uncontrolled-tab-example-tab-TeenPatti.active {
          background-image: url("../public/images/Teenpatti.png");
        }

        #uncontrolled-tab-example-tab-TeenPatti {
          background-image: url("../public/images/games-image/Teenpatti.png");
        }

        #uncontrolled-tab-example-tab-32Card.active {
          background-image: url("../public/images/casino.png");
        }

        #uncontrolled-tab-example-tab-32Card {
          background-image: url("../public/images/games-image/casino.png");
        }

        #uncontrolled-tab-example-tab-Seven.active {
          background-image: url("../public/images/seven.png");
        }

        #uncontrolled-tab-example-tab-Seven {
          background-image: url("../public/images/games-image/seven.png");
        }

        #uncontrolled-tab-example-tab-Dices.active {
          background-image: url("../public/images/dices.png");
        }

        #uncontrolled-tab-example-tab-Dices {
          background-image: url("../public/images/games-image/dices.png");
        }

        #uncontrolled-tab-example-tab-BetNumber.active {
          background-image: url("../public/images/bet_on_numbers.png");
        }

        #uncontrolled-tab-example-tab-BetNumber {
          background-image: url("../public/images/games-image/bet_on_numbers.png");
        }
      }
    }
  }
}

.live_heading-center {
  text-align: center;
  color: #ffc400;
}

.Live_casino_tabs {
  .live_heading {
    img {
      width: 10%;
    }
  }

  .live_heading.ev-heading {
    img {
      width: 13%;
    }
  }

  .hot_games {
    ul {
      li {
        flex: 1;
      }
    }
  }
}

.profit_loss_heading {
  background: var(--tableHeadBG) !important;
  color: var(--primaryClr);
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid var(--primaryClr);
}

.table {
  background: var(--tableBG);
  margin: 0;

  thead {
    background-color: var(--headClr);

    tr {
      border-color: var(--tableBrder);
      border-top-color: var(--tableBorderTop) !important;

      th {
        color: var(--whiteClr);
        border: 0;
      }
    }
  }

  tbody {
    color: var(--whiteClr);

    tr,
    &:nth-of-type(odd) {
      border-bottom: 1px solid #1a3556;

      td {
        background: var(--headClr) !important;
        --bs-table-accent-bg: var(--headClr) !important;
        color: var(--whiteClr);
        border: 0;

        .edit_password_button {
          .btn {
            background: transparent;
            padding: 0;
            border: 0;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (orientation: landscape) {
  .all-providers-head {
    display: flex;
  }

  .provider-main {
    margin-top: 10px;
  }

  .providers-img {
    width: 30%;
    margin: 0 10px;
  }

  .providers-img img {
    width: 100%;
    height: 100%;
  }

  #basic-navbar-nav {
    .provider-img {
      width: 60px;
    }
  }
}

.Qr-code-scanner {
  display: flex;
  justify-content: center;

  img {
    max-width: 250px;
    height: auto;

    @media screen and (max-width: 575.98px) {
      max-width: 180px;
    }
  }
}

.Toastify .Toastify__toast-container {
  z-index: 999999999999 !important;
}

.country-name {
  color: var(--tertiory8);
}

.country-list {
  li {
    display: table;
  }
}

.fact_sec {
  padding: 20px 0;

  a {
    background: var(--secondary4) 0% 0% no-repeat padding-box;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    max-width: 210px;
    height: 80px;
    display: block;
    padding: 0 10px 0 0;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 5px 5px 6px rgb(0 0 0 / 5%);

    .tabs-inner-box {
      display: flex;
      align-items: flex-end;
      height: 100%;

      .mob-all-icon {
        img {
          width: 70px;
        }
      }

      .web-game-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .option-name {
          color: var(--tertiory2);
          font-size: 15px;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .play-btn {
          color: var(--primaryClr);
          font-size: 10px;
          margin-bottom: 0;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 28px !important;
    height: 29px !important;
    background: #c9a145 !important;
    border-radius: 50% !important;

    &:before {
      line-height: 5px !important;
      font-size: 26px !important;
    }
  }

  .slick-prev {
    left: -22px !important;

    &:before {
      content: "\00AB" !important;
    }
  }

  .slick-next {
    right: -22px !important;

    &:before {
      content: "\00BB" !important;
    }
  }

  .fact-sec-mobile {
    ul {
      display: grid !important;
      gap: 15px;
      grid-template-columns: repeat(4, 1fr);

      li {
        a {
          background: transparent !important;
          font-size: 12px;
          font-weight: 600;
          height: auto;
          padding: 0;
          box-shadow: none;

          .mob-all-icon {
            background-color: var(--lytThemeClr2);
            display: block;
            border-radius: 12px;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .option-name {
            color: var(--tertiory2);
            font-size: 11px;
            font-weight: 700;
            line-height: 13px;
            margin-bottom: 0;
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.game_types {
  .desktop-live-deal {
    padding-top: 30px;

    .section-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 767.98px) {
        flex-wrap: wrap;
      }

      h3 {
        color: var(--headClr);
        font-size: 18px;
        margin-bottom: 0;
        position: relative;

        @media (max-width: 767.98px) {
          margin-bottom: 20px;
        }

        &:after {
          content: "";
          position: absolute;
          height: 2px;
          width: 57px;
          background-color: var(--primaryClr);
          bottom: -4px;
          left: 0;
          display: none;
        }

        a {
          @media (max-width: 767.98px) {
            display: none;
          }
        }
      }
    }

    .mob-images-section {
      margin-top: 25px;

      @media (max-width: 767.98px) {
        margin-top: 0px;
      }

      .mob-images-head {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: 767.98px) {
          grid-template-columns: repeat(2, 1fr);
        }

        .mob-images {
          border-radius: 8px;
          box-shadow: 3px 5px 8px rgb(0 0 0 / 28%);

          img {
            width: 100% !important;
            border-radius: 8px;
          }

          &.showall_btn {
            grid-column: 1 / 3;
            position: relative;

            img {
              border-radius: 8px 8px 0 0;
            }

            a {
              background: var(--secondary5) !important;
              margin: 0;
              font-weight: 500;
              border-radius: 0 0 8px 8px;
              width: 100%;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

.is-invalid ~ .icon {
  top: 24% !important;
}

.is-invalid ~ span.icon {
  top: 24% !important;
}

.not-registered {
  margin-top: 12px;

  p {
    margin-bottom: 0 !important;
    line-height: inherit !important;
    font-size: 13px !important;
  }

  ul {
    display: flex;
    justify-content: center;
    margin-top: 5px;

    li {
      margin-right: 10px;
    }
  }
}

.game-page {
  .provider-game-section {
    margin-bottom: 25px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 19%;
        margin: 0.5%;

        @media only screen and (max-width: 767.98px) {
          width: 32% !important;
        }

        @media only screen and (max-width: 575.98px) {
          width: 49% !important;
        }
      }
    }
  }
}

.providers-img {
  margin: 10px !important;
}

.filter-added {
  display: flex;

  .dropdown {
    width: 32%;
    height: 45px;
    margin: 0.5%;

    button {
      background: #1e1e1e;
      border: 1px solid var(--primaryClr);
      border-radius: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        background: #1e1e1e;
        border: 1px solid var(--primaryClr);
      }
    }

    .dropdown-menu {
      width: 100%;
      border-radius: 0;
      background: #1e1e1e;
      padding: 0;

      a {
        color: #b8b8b8;
        border-bottom: 1px solid #3a3a3a;
        padding: 6px 12px;

        &:hover {
          background-color: transparent;
          color: #b8b8b8;
        }
      }
    }
  }

  .favrouite {
    width: 45px;
    height: 45px;
    background: #1e1e1e;
    border: 1px solid #c9a145;
    margin: 0.5%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      font-size: 26px;
      fill: var(--primaryClr);
    }
  }
}

.support-header {
  text-align: center;

  .favicon {
    padding: 30px;
    background-color: #004e8a;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .support-heading {
    padding: 18px 0;

    h4 {
      color: var(--headClr);
      font-size: 24px;
      font-weight: 600;
    }

    p {
      color: #3d4244;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .chat-call-support {
    display: flex;
    justify-content: center;
    margin-top: 0;

    .chat-box {
      width: 25%;
      text-align: left;
      padding: 0 25px;

      &:nth-child(2) {
        border-right: 1px solid #a5a5a5;
        border-left: 1px solid #a5a5a5;
      }

      h2 {
        color: var(--headClr);
        font-size: 16px;
      }

      p {
        color: #3d4244;
        font-size: 28px !important;
        font-weight: 500;
      }

      ul {
        display: flex;
        justify-content: center;

        li {
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 35px;
          }
        }
      }

      .email-section {
        margin-bottom: 20px;
        margin-top: 15px;

        p {
          font-size: 14px;
          color: var(--headClr);
          margin: 0;
        }

        a {
          color: #3d4244;
          text-decoration: underline;
          font-size: 25px;
        }
      }
    }
  }
}

.footer-page-section {
  margin: 0 30px;
  @media only screen and (max-width: 767px) {
    margin: 0 20px;
  }
  p {
    color: #b4b4b4;
  }
}
.privacy_policy {
  min-height: inherit !important;
  h4,
  h6 {
    color: var(--whiteClr);
  }
  p {
    color: #899fdb;
    margin-top: 20px;
  }
}

// sportsbook css
.main {
  .leftbarSec {
    background: var(--bodyClr);
    width: 200px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding-bottom: 10px;
    border-right: 1px solid hsla(0, 0%, 100%, 0.1);
    box-shadow: 20px 0 20px -20px #00030e;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      // position: fixed;
      // transform: translateX(-100%);
      transition: all 0.3s;
      z-index: 999;
    }
    @media only screen and (max-width: 767.98px) {
      width: 70%;
      position: relative;
      top: -65px;
      height: calc(100vh - 65px);
    }

    .games_link {
      h6 {
        color: var(--whiteClr);
        font-size: 13px;
        font-weight: 700;
        text-transform: capitalize;
        padding: 10px 10px 10px;
        margin-bottom: 0;
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 105px;
          height: 1px;
          top: 19px;
          right: 0;
          background-color: var(--whiteClr);
        }
      }

      ul {
        padding: 0 0 15px;
        li {
          cursor: pointer;
          border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;

            &:before {
              position: absolute;
              content: "";
              top: 0;
              left: -10px;
              width: 2px;
              height: 100%;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                opacity: 1;
                left: 0;
              }
            }
            img {
              width: 20px;
              height: auto;
              filter: brightness(1000);
            }
            span {
              color: var(--whiteClr);
              font-size: 12px;
              font-weight: 600;
              text-transform: capitalize;
              margin-left: 10px;
              opacity: 0.9;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--blackClr);
              font-size: 12px;
              width: 24px;
              height: 18px;
              margin-left: auto;
              padding: 0.25em 0.4em;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }

  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 105px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: calc(100% - 500px);
    }
    @media only screen and (min-width: 768px) and (max-width: 1199.98px) {
      width: calc(100% - 200px);
    }
  }

  .rightbarSec {
    background: var(--bodyClr);
    width: 400px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding: 10px;
    border-left: 1px solid hsla(0, 0%, 100%, 0.1);
    box-shadow: -20px 0 20px -20px #00030e;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }

    @media only screen and (max-width: 1199.98px) {
      display: none;
    }

    h6 {
      color: var(--whiteClr);
      font-size: 12px;
      font-weight: 600;
      text-transform: capitalize;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--headClr);
    }

    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;

      .slick-slider {
        position: relative;

        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;

          img {
            border-radius: 5px;
          }

          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 5px;
          }
        }

        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;

          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);

            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;

              &::before {
                color: #fff;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }

            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }

            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }

            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }

            &.slick-active {
              button {
                background: var(--primaryClr);
                color: #fff;

                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }

    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);

      a {
        position: relative;

        img {
          border-radius: 5px;
        }

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;

          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }

        &:hover {
          .overlay {
            opacity: 0.8;

            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }

    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }

      @media only screen and (max-width: 991.98px) {
        display: none;
      }

      .accordion {
        .accordion-item {
          background-color: var(--headClr);
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);

          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;

              img {
                width: 20px;
                margin-right: 10px;
              }

              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }

              &:hover,
              &:focus {
                background: transparent !important;
              }

              &:after {
                content: none;
              }
            }
          }

          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;

            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;

                  thead {
                    background: #0c1d2c;
                    display: block;

                    tr {
                      th {
                        color: #ffffff;
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;

                        &:not(:first-child) {
                          min-width: 60px;
                        }

                        &:first-child {
                          min-width: 35px;
                        }

                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }

                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;

                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }

                    &::-webkit-scrollbar-track {
                      background: #222;
                    }

                    &::-webkit-scrollbar-thumb {
                      background: #333;

                      &:hover {
                        background: #444;
                      }
                    }

                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;

                        &:not(:first-child) {
                          min-width: 60px;
                        }

                        &:first-child {
                          min-width: 35px;
                        }

                        &:last-child {
                          width: 100%;
                        }

                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;

                          &.lay {
                            background: #72bbef;
                          }

                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .no_data {
              color: #000;
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }

          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);

              .live_tv_box {
                color: var(--whiteClr);
                height: 215px;
              }
            }
          }

          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;

    .match_info_slider {
      .info_box {
        background: #222430;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;

        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;

          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }

        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;

          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;

            .flag {
              width: 28px;
              height: 28px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }

            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .market {
          display: flex;
          gap: 4px;

          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;

            .name {
              color: #abb0ba;
            }

            .odds {
              color: #e7cf90;
            }
          }
        }
      }

      .slick-arrow {
        background: #222430;
        height: 100%;

        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }

        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
}

.sports_page,
.custom-tabs {
  padding-top: 10px;

  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondary2);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;

      li {
        margin: 0 5px;

        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;

          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }

  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
    position: relative;

    a.openbets {
      border: 1px solid var(--whiteClr);
      color: var(--whiteClr);
      padding: 8px 18px;
      border-radius: 25px;
      position: absolute;
      right: 10px;
      top: 50%;
      text-transform: uppercase;
      transform: translateY(-50%);
    }

    .nav-item {
      margin: 0 5px;

      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;

        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }

  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;

      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }

      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }

    .game-listing {
      background: var(--whiteClr);
      border: 1px solid #dddddd;
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;

        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }

          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }

      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid #dddddd;

        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }

        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }

        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }

        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;

          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--blackClr);
            cursor: pointer;

            .date_time_info {
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;

              .date {
                color: #4e944c;
                font-size: 11px;
                font-weight: 600;
                line-height: 16px;
                text-transform: uppercase;
              }

              &.in-play {
                background: var(--headClr);

                .date {
                  color: var(--primaryClr) !important;
                }

                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );

                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;

                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }

                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }

                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;

                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }

                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }

                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;

                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }

                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }

                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;

                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }

                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }

        .listing {
          display: flex;
          flex-wrap: nowrap;

          li {
            margin-right: 5px;
            width: 43px;
            height: 30px;

            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }

            &:last-child {
              margin-right: 0;
            }

            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;

              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }

              &.yes {
                background: var(--oddsBack);
              }

              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;

      .bet-btns {
        display: flex;

        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }

    .status-btns {
      display: flex;
      align-items: center;

      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);

        &.yes {
          background: var(--oddsBack);
        }

        &.no {
          background: var(--oddsLay);
        }
      }
    }

    .game-points {
      ul {
        margin: 0 -1rem;

        li {
          display: flex;
          border-bottom: 1px solid var(--secondary2);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;

          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;

            .time {
              color: var(--siteRedClr);
            }
          }

          .score {
            margin-left: auto;
          }

          .status-btns {
            margin-left: 5px;
            position: relative;

            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;

              span {
                font-size: 12px;
              }
            }

            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;

      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }

      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }

      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      // padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}

.match_details_container {
  background: var(--whiteClr);
  padding-top: 15px;

  .math_info {
    text-align: center;

    .match_type {
      background: var(--primaryClr);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;

      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }

        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }

        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }

        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }

      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;

        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }

    .team_names {
      margin: 15px 0 10px;

      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;

        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }

        span {
          color: rgb(237, 33, 58);
          font-size: 12px;

          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }

        &:last-child {
          span {
            display: none;
          }
        }
      }
    }

    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;

      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        margin-right: 5px;

        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }

        img {
          width: 14px;
        }
      }

      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;

        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }

        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }

        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }

        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }

  .sports-match-iframe {
    height: 100%;

    @media screen and (min-width: 1400px) {
      height: 390px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }

    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }

    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }

    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }

    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }

    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }

    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }

    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }

    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }

    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }

    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }

    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}

.page-details {
  .game-points {
    padding-bottom: 0;
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;

    .back-link {
      margin-top: 0;
    }

    ul.bet-btns {
      display: flex;

      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }

  .game-top-area {
    background: var(--headClr);
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }

    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }

    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .title {
    background: #132b41;
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }

    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }

    .status-btns {
      display: flex;
      align-items: center;

      @media screen and (max-width: 767.98px) {
        width: 136px;
      }

      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;

        &.yes {
          background: var(--oddsBack);
        }

        &.no {
          background: var(--oddsLay);
        }

        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }

  .game-points {
    border: 1px solid #132b41;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;

    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondary2);
        padding: 1px 0 1px 0.75rem;
        align-items: center;

        .score {
          margin-left: auto;
          margin-right: 5px;
        }

        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;

          .time {
            color: var(--siteRedClr);
          }
        }

        .status-btns {
          display: flex;
          align-items: center;
          position: relative;

          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }

          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }

        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;

          &.yes {
            background: var(--oddsBack);
          }

          &.no {
            background: var(--oddsLay);
          }

          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.sports_page_banner {
  margin: 10px 10px 0 !important;
}

.sports_page_banner img {
  border-radius: 10px;
  width: 99%;
}

.sports_page_banner .slick-slider .slick-dots {
  bottom: 8px;
}

.sports_page_banner .slick-slider .slick-dots li {
  height: 3px;
}

.sports_page_banner .slick-slider .slick-dots li button {
  height: 3px;
  padding: 0;
}

.sports_page_banner .slick-slider .slick-dots li button:before {
  background: hsla(0, 0%, 100%, 0.7);
  color: transparent;
  height: 3px;
  opacity: 1;
  width: 25px;
}

.sports_page_banner .slick-slider .slick-dots li.slick-active button:before {
  background-color: var(--primaryClr);
}

.betslip_popup {
  .modal-body {
    background: var(--headClr);
    padding: 0;
    .stack-win-value {
      flex-wrap: wrap;
      div {
        width: 46%;
      }
    }

    .auto-stake {
      ul {
        li {
          .stack-btn {
            background: var(--secondary2);
            color: var(--whiteClr);
          }
        }
      }
    }
  }
}

.betslip-content.inline_betslip {
  padding: 0.5rem;
  border: 1px solid var(--secondary2);
  box-shadow: 0 2px 4px rgba(255, 189, 0, 0.12);

  .stack-win-value {
    display: flex;
    margin-bottom: 5px;

    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      color: var(--whiteClr);

      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondary2);
      }
    }
  }

  .winning p {
    line-height: 1.3;
    margin-bottom: 0;
  }

  .auto-stack {
    margin-bottom: 10px;

    ul {
      display: flex;
      align-items: center;

      li {
        width: 20%;
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }

        .stack-btn {
          background: var(--secondary2);
          color: var(--whiteClr);
          width: 100%;
        }
      }
    }
  }

  .btn_group {
    display: flex;

    > div {
      width: calc(50% - 5px);
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .bet-now {
      .place-btn {
        background: #0cd664;
        color: #000;
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
      }
    }

    .cancel-btn {
      background: #af2f2f;
      color: #000;
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
    }
  }
}

.live-deal-section {
  ul {
    display: flex;

    li {
      width: 25%;
      margin: 0.5%;
    }
  }
}

.deposit_img {
  img {
    animation: moving 3s infinite linear;

    @keyframes moving {
      0% {
        left: 0;
      }

      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }

      100% {
        left: 0;
      }
    }
  }
}

.transfer_details {
  .payment-transfer-deposit {
    background: #313131;
    border-radius: 8px;
    color: var(--tertiory5);

    h5 {
      margin: 0;
      padding: 10px 0;
      background: #3a3a3a;
      border-radius: 8px 8px 0 0;
      color: var(--tertiory5);
      font-size: 1.5rem;
    }

    .bankdetailsdeposit {
      padding: 15px 20px;

      p {
        color: var(--tertiory5);
        font-size: 14px;
      }
    }
  }
}

.steps-canvas .form-steps input {
  margin-bottom: 15px;
  border-radius: 10px !important;
}

.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;

  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;

    li {
      width: 50%;

      button {
        background: linear-gradient(0deg, #90c5ee 0%, #1f79be 100%);
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #fff;

        &.active {
          background: var(--primaryClr);
          border-radius: 15px 15px 0px 0px;
          color: #000;
        }
      }
    }
  }

  .tab-content {
    background: var(--headClr);
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        // background: linear-gradient(0deg, #eef2f5 0%, #ffffff 100%);
        background: linear-gradient(0deg, #042246 0%, #000a2c 100%);
        // border: 0.5px solid #d6d6d6;
        border: 0.5px solid #0f3058;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;

        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          // border-bottom: 0.5px solid #d6d6d6;
          border-bottom: 0.5px solid #0f3058;
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          color: var(--whiteClr) !important;
          padding: 11px 4px;
          font-weight: 500;
          font-size: 12px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.bankDetails {
  padding: 15px 0 0 5px;
  p {
    color: var(--whiteClr);
  }
}
.gatewayForm {
  margin: 25px;
  input {
    border-color: #4a5d6b;
    padding: 14px 20px;
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  // background: var(--headClr);
  align-items: center;

  h4 {
    margin: 0;
    color: var(--headClr);
  }
}

.new_table {
  thead {
    th {
      padding: 5px;
    }
  }

  tbody {
    background: #2b2d30;

    tr td {
      border: 0;
    }
  }

  padding: 6px;
}

.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;

  .stats-card {
    display: flex;

    ul {
      border-right: 1px solid #353c4b;
      width: 50%;

      &:last-child {
        border: 0;
      }

      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;

        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}

.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .progress {
    background: #393939;
  }
}

.cards {
  span {
    color: orange;
    margin-left: 4px;
  }

  padding: 15px;
  border-radius: 10px;
  width: 32.33%;
  margin: 0.5%;
  background: #1e1e1e;
  box-shadow: 0px 1px 10px 3px #3a3a3a70;
}

.amount_data {
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.amount_data {
  .status {
    color: green;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  .status_claimed {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  .staus_pending {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  .staus_expired {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  .staus_canceled {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cancel_bonus {
  background: red;
  padding: 2px 4px;
  font-size: smaller;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
}

.cancel_bonus_modal {
  .modal-dialog {
    .modal-content {
      background: #fff;
      color: #000;
    }
  }
}

.eye_ {
  fill: var(--primaryClr);
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.referral_card_main {
  display: flex;
  justify-content: normal !important;
  margin-top: 10px !important;
  gap: 15px;

  .referrals_cards {
    margin-top: 10px;
    width: 100%;
    background: #004e8a;
    border-radius: 5px;
    flex: 1 1;

    h5 {
      font-size: 1rem;
      color: var(--whiteClr);
    }

    .referr_user {
      display: flex;
      gap: 10px;
      color: var(--whiteClr);

      svg {
        fill: var(--primaryClr);
      }

      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}

.referral_link {
  background-color: var(--headClr);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;

  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 20px;
    color: var(--whiteClr);
  }

  div {
    background: #004e8a;
    border-radius: 5px;
    color: #e7e4e4;
    flex: 1 1;
    height: 37px;
    padding: 8px 15px;
  }
}

.referr_tabs ul {
  border-bottom: 0;
  gap: 20px;

  button {
    background: #004e8a !important;
    border: 0 !important;
    color: #fff !important;
    padding: 8px 35px;
  }
}

.referr_table {
  color: #fff;

  thead {
    background: var(--headClr);
    border: 0;

    th {
      border: 0;
    }
  }

  tbody {
    background-color: #000;

    td {
      border-color: #333;
    }
  }
}

.support-buttons ul {
  grid-auto-flow: column;
  padding: 0px 10px 10px;
  grid-row-gap: 7px;
  width: auto;
  overflow-x: auto;
  margin: 50px 0 10px;
  gap: 5px;
  display: grid;
  justify-content: space-between;
  @media (max-width: 767px) {
    grid-auto-flow: unset;
    grid-template-columns: repeat(2, 1fr);
  }
  li {
    flex: 1 1;
    margin: 0 !important;
    width: 100%;
    font-family: "Lato", sans-serif !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-transform: capitalize !important;

    a.support-buttons {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: initial;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      color: var(--whiteClr);
    }
  }
}

.support-buttons img {
  border-radius: 16px;
  @media (max-width: 767px) {
    border-radius: 8px;
  }
}

.sport-buttons ul {
  padding: 20px 10px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;

  li {
    background-color: initial;

    a {
      position: relative;
      color: var(--whiteClr);
      display: block;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
        @media (max-width: 767px) {
          border-radius: 8px;
        }
      }

      .sports-content-text {
        position: absolute;
        top: 3px;
        left: 5px;
        display: flex;
        flex-direction: column;

        img {
          width: 16px;
          height: 16px;
        }

        span {
          text-transform: none;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

.promotion-buttons {
  @media only screen and (max-width: 767.98px) {
    background-image: url(./assets/images/casinogirl.png),
      linear-gradient(#1d1d8b, #0a144b);
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 10px;
    margin: 48px 10px;
    padding-bottom: 100px;
  }
  ul {
    grid-auto-flow: column;
    padding: 10px 10px 0;
    grid-row-gap: 7px;
    width: auto;
    overflow-x: auto;
    margin: 20px 0;
    gap: 5px;
    display: grid;
    justify-content: space-between;
    @media (max-width: 767px) {
      grid-auto-flow: unset;
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      flex: 1 1;
      font-size: 12px !important;
      font-weight: 700 !important;
      font-family: "Lato", sans-serif !important;
      font-style: normal;
      text-transform: capitalize !important;
      line-height: 14px !important;
      width: 100%;
      padding: 0 5px;
      margin: 0 !important;
      a.promotion-banner {
        align-items: center;
        background-color: initial;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        color: #fff;
        color: var(--whiteClr);
        height: 100%;
        width: 100%;

        // &.promotionBanner1 {
        //   background-image: url(./assets/images/ic-new/ic-aviator.png);
        //   background-size: 100% auto;
        // }
        // &.promotionBanner2 {
        //   background-image: url(./assets/images/ic-new/ic-slots.png);
        //   background-size: 100% auto;
        // }
        // &.promotionBanner3 {
        //   background-image: url(./assets/images/ic-new/ic-livecasinos.png);
        //   background-size: 100% auto;
        // }
        // &.promotionBanner4 {
        //   background-image: url(./assets/images/ic-new/ic-sportsbook.png);
        //   background-size: 100% auto;
        // }
        &.promotionBanner5 {
          background-image: url(./assets/images/banner/home/e-cricket-quick-bg.png);
          background-size: 100% auto;
        }

        &.promotionBanner6 {
          background-image: url(./assets/images/banner/home/cardgames-quick-bg.png);
          background-size: 100% auto;
        }

        &.promotionBanner7 {
          background-image: url(./assets/images/banner/home/slot-quick-bg.png);
          background-size: 100% auto;
        }

        &.promotionBanner8 {
          background-image: url(./assets/images/banner/home/multiplayer-quick-bg.png);
          background-size: 100% auto;
        }

        &.promotionBanner9 {
          background-image: url(./assets/images/banner/home/fishinggames-quick-bg.png);
          background-size: 100% auto;
        }

        img {
          width: 100%;
          border-radius: 5px;
        }
      }
      p {
        color: var(--primaryClr);
        font-size: 12px;
        margin-top: 5px !important;
        text-align: center;
      }
    }
  }
}
.indian-card-games {
  .game-heading {
    min-height: 48px;
    display: flex;
    padding: 5px 9px;
    margin-top: 30px;
    // background-color: var(--headClr);
    color: var(--whiteClr);
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    position: relative;

    a.openbets {
      background-color: #fdca01;
      font-weight: 600;
      color: var(--blackClr);
      padding: 5px 15px;
      border-radius: 25px;
      position: absolute;
      right: 7px;
      top: 50%;
      text-transform: uppercase;
      transform: translateY(-50%);

      &:hover {
        background: var(--primaryClr);
        border-color: var(--primaryClr);
        color: var(--blackClr);
      }
    }

    h3 {
      font-size: 16px;
      margin: 0;

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }

  ul {
    width: 100%;
    display: grid;
    grid-template-rows: auto auto auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    margin-top: 12px;
    padding-bottom: 4px;
    justify-content: space-between;

    li {
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;
      width: 170px;

      img {
        height: 78px;
        width: 100%;
      }
    }
  }
}

.populaGamesSection {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow: hidden;
    padding: 0;
    justify-content: start;
    li {
      width: calc(16.66% - 8.33px);
      @media (max-width: 991.98px) and (min-width: 768px) {
        width: calc(20% - 8px);
      }
      @media (max-width: 767.98px) and (min-width: 576px) {
        width: calc(25% - 7.5px);
      }
      @media (max-width: 575.98px) {
        width: calc(33.33% - 6.66px);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.CasinoSection {
  ul {
    justify-content: space-between;
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    overflow-x: auto;
    grid-row-gap: 2px;
    grid-column-gap: 2px;
    grid-template-columns: repeat(auto-fit, 117px);

    li {
      width: 117px;
      height: 70px;

      a {
        display: block;
        width: 100%;
        height: 100%;

        img {
          height: 70px;
        }
      }
    }
  }
}

.favicon img {
  width: 150px;
  margin-bottom: 20px;
}

.fastgameMob a {
  background: transparent !important;
  padding: 0 !important;
  height: auto !important;
}

.mobleftbarPage {
  .upperbalnace {
    background: #001244;
    padding: 15px;
    .balance {
      color: var(--whiteClr);
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      img {
        margin-right: 14px;
        width: 24px;
      }
    }

    .balance-amount {
      color: var(--whiteClr);
      font-size: 16px;
      font-weight: 600;
      text-align: right;
    }

    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      border-radius: 25px;
      border-color: var(--whiteClr);
      color: var(--whiteClr);

      &.btn-outline-secondary {
        border-color: var(--whiteText);
        color: var(--whiteText);
      }

      &.btn_withdraw {
        color: var(--whiteClr);
        width: 100%;
        border-color: var(--whiteClr);
      }

      &.btn_primary {
        background: var(--primaryClr);
        border-color: var(--primaryClr);
        color: var(--blackClr);
        width: 100%;
      }
    }

    .col-6 {
      line-height: initial;
    }
  }

  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--headClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--myProfileHeadText);

    img {
      width: 48px;
      height: 48px;
      border: 0;
      padding: 11px;
    }

    svg {
      fill: gold;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .user_name,
    p {
      color: var(--secondary4);
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
    }
  }

  .btn-close {
    opacity: 1;
    filter: brightness(0.5);

    &:focus {
      box-shadow: none;
    }
  }

  .profilemobsection {
    background: var(--tertiory1);
    padding: 10px;
  }
}

.SocialMediaSection {
  ul {
    display: flex;
    gap: 8px;
    margin: 0 0 30px;
    justify-content: center;
    flex-wrap: wrap;

    li {
      width: 24%;
      border: 1px solid var(--headClr);
      border-radius: 10px;
      background-color: #fdca01;

      a {
        color: var(--headClr);
        white-space: nowrap;
        padding: 10px;
        display: block;
        font-size: 15px;
        font-weight: 600;

        img {
          width: 25px;
          margin-right: 15px;
        }
      }
    }
  }
}

.head-social {
  display: none;
}

.afterlogin {
  .headerRight {
    .searchbar {
      margin-left: 0;
      margin-right: 5px;
    }
  }
}

// .searchGames {
//   display: flex;
//   // margin-inline: 5px !important;
//   border-radius: 16px;
//   background: #000000;
//   border: 1px solid goldenrod;
//   align-items: center;
//   justify-content: center;
//   padding: 5px 8px;
//   cursor: pointer;
//   p {
//     margin-right: 8px;
//     margin-bottom: 0;
//   }
// }
.GamesShow {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;

  .displayGames {
    width: calc(50% - 8px);
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 2px 8px 2px rgb(4 34 70 / 42%);
    a {
      display: block;
      img {
        max-height: 150px;
        border-radius: 5px;
      }
    }
  }
}

.searchGames {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0017bc;
  gap: 10px;
  padding: 4px 12px;
  border-radius: 20px;
  cursor: pointer;

  p {
    margin: 0 !important;
  }

  svg {
    font-size: large;
  }
}

.login-modal {
  .modal-content {
    width: 95%;
    justify-content: center;
    // height: 95vh;
    margin: 0 auto;

    .modal-header button {
      color: #000;
    }
  }
}

.home-iframe {
  margin: 15px 0 0;

  iframe {
    width: 100%;
    height: 400px !important;
  }
}

/// css for latest changes 08/10/2023
.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--headClr);
  opacity: 0.8;
}
.slots-section {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 0 15px;
    li {
      background-color: transparent;
      display: block;
      width: calc(14.285% - 8.57px);
      height: auto;
      padding: 0;
      position: relative;
      @media only screen and (max-width: 991.98px) and (min-width: 576px) {
        width: calc(20% - 8px);
      }
      @media only screen and (max-width: 575.98px) {
        width: calc(33.33% - 6.66px);
      }
      a {
        display: block;
        img {
          width: 100%;
          border-radius: 5px;
        }
        .casino_overlay {
          content: "";
          position: absolute;
          background: linear-gradient(
            0deg,
            rgba(37, 35, 152, 0.49),
            rgba(37, 35, 152, 0.49)
          );
          inset: 0 0 0 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          transition: all 0.9s;
          img {
            width: 0 !important;
            height: 0;
            border-radius: 10px;
          }
        }
        &:hover {
          .casino_overlay {
            inset: 0 0 0 0;
            img {
              height: 80px !important;
              width: 80px !important;
            }
          }
        }
      }
    }
  }
}
.login-modal-img {
  max-width: 150px;
  margin-bottom: 20px;
}
.pageHeadingSec {
  // background: var(--headClr);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 15px 0;
  border-radius: 2px;
  h2 {
    color: var(--whiteClr);
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }
}
@media only screen and (min-device-width: 280px) and (max-device-width: 812px) {
  .main .leftbarSec {
    height: calc(100vh - 65px);
    height: calc(var(--vh, 1vh) * 100 - 65px);
  }
  .live_casino_page.live .ezugi_iframe iframe {
    height: calc(100vh - 70px) !important;
    height: calc(var(--vh, 1vh) * 100 - 70px) !important;
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: var(--primaryClr) !important;
            color: #000 !important;
            border-color: var(--primaryClr) !important;
            // background: linear-gradient(
            //   100deg,
            //   rgb(43 133 243) 0%,
            //   rgb(131 175 228) 100%
            // ) !important;
          }
        }
      }
    }
    .tab-content {
      @media only screen and (max-width: 991.98px) {
        padding-bottom: 60px;
      }
      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .table-responsive {
          padding-bottom: 7px;
        }
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          color: var(--whiteClr);
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            background-color: #2c2f3d;
            th {
              background: transparent !important;
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: var(--headClr) !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: rgb(6, 99, 177) !important;
              background: linear-gradient(
                100deg,
                rgba(6, 99, 177, 1) 0%,
                rgba(40, 131, 239, 1) 100%
              ) !important;
              color: var(--whiteClr) !important;
              border-color: rgb(6, 99, 177) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          .table-responsive {
            padding-bottom: 7px;
          }
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: var(--headClr) !important;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          color: #000;
          font-weight: 500;
          height: 45px;
          margin-right: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px !important;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--whiteClr);
    color: var(--whiteClr);
    border: 0;
    .modal-title {
      color: var(--blackClr);
    }
  }
  .modal-body {
    background: var(--bodyClr);
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.25em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.marqu {
  height: 25px;
  background: #201e1e !important;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9999;
  img {
    width: 35px;
  }
  marquee {
    color: var(--whiteClr);
    width: 100%;
  }
  span {
    position: absolute;
    width: 35px;
    height: 25px;
    top: 0;
    right: 0;
    background: #201e1e;
    display: flex;

    color: #fff !important;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
}

.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #ffffff;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}

.HpaySection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  background: var(--blackClr);
  position: relative;
  margin: 95px auto;
  .HayQR {
    cursor: pointer;
    img {
      width: 30px !important;
      height: 30px !important;
      position: absolute;
      right: -14px;
      top: -16px;
      margin-top: 0 !important;
    }
  }
  img,
  canvas {
    width: 300px !important;
    height: 300px !important;
    margin-top: 10px !important;
  }
}
